import {
  OpenAPIRegistry,
  extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import {
  CreateGigJobBodySchema,
  CreateGigJobResponseSchema,
  UpdateGigJobBodySchema,
  UpdateGigJobResponseSchema,
  CancelGigJobResponseSchema,
  AcceptGigJobResponseSchema,
  RejectGigJobResponseSchema,
  RejectGigJobBodySchema,
  GetGigJobsQuerySchema,
  GetGigJobsResponseSchema,
  GetGigJobsByRiderIdQuerySchema,
  GetGigJobsByRiderIdResponseSchema,
  AcceptGigJobResponseResponseSchema,
  RejectGigJobResponseBodySchema,
  RejectGigJobResponseResponseSchema,
  GetGigJobByIdResponseSchema,
  MarkGigJobsAsPaidBodySchema,
  MarkGigJobsAsPaidResponseSchema,
  DownloadGigJobPaymentSummaryQuerySchema,
  DownloadGigJobPaymentSummaryResponseSchema,
  GetGigJobPaymentSummaryQuerySchema,
  GetGigJobPaymentSummaryResponseSchema,
  GetGigJobsForJobBoardQuerySchema,
  GetGigJobsForJobBoardResponseSchema,
  CheckRiderPriorityResponseSchema,
  DownloadGigJobsResponseSchema,
  DownloadGigJobsQuerySchema
} from '../../delivery-service';
import { ApiErrorSchema } from '../../shared';
import { z } from 'zod';
import { commonErrorResponses } from './common';

extendZodWithOpenApi(z);

const registry = new OpenAPIRegistry();

const basePath = '/gig-jobs';

/**
 * GET /gig-jobs
 */
registry.registerPath({
  operationId: 'getGigJobs',
  path: `${basePath}/`,
  description: 'Get gig jobs.',
  method: 'get',
  request: {
    query: GetGigJobsQuerySchema,
  },
  responses: {
    200: {
      description: 'Gig jobs fetched successfully.',
      content: {
        'application/json': {
          schema: GetGigJobsResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /gig-jobs/job-board/{riderId}
 */
registry.registerPath({
  operationId: 'getGigJobsForJobBoard',
  path: `${basePath}/job-board/{riderId}`,
  description: 'Get gig jobs for job board.',
  method: 'get',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
    query: GetGigJobsForJobBoardQuerySchema,
  },
  responses: {
    200: {
      description: 'Gig jobs fetched successfully.',
      content: {
        'application/json': {
          schema: GetGigJobsForJobBoardResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});


registry.registerPath({
  operationId: 'getGigJobsForJobBoard',
  path: `${basePath}/job-board/{riderId}`,
  description: 'Get gig jobs for job board.',
  method: 'get',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
    query: GetGigJobsForJobBoardQuerySchema,
  },
  responses: {
    200: {
      description: 'Gig jobs fetched successfully.',
      content: {
        'application/json': {
          schema: GetGigJobsForJobBoardResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /gig-jobs/{gigJobId}
 */
registry.registerPath({
  operationId: 'getGigJobById',
  path: `${basePath}/{gigJobId}`,
  description: 'Get gig job by ID.',
  method: 'get',
  request: {
    params: z.object({
      gigJobId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Gig job fetched successfully.',
      content: {
        'application/json': {
          schema: GetGigJobByIdResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /gig-jobs/
 */
registry.registerPath({
  operationId: 'createGigJob',
  path: `${basePath}/`,
  description: 'Create a gig job.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: CreateGigJobBodySchema,
        },
      },
    },
  },
  responses: {
    201: {
      description: 'Gig job created successfully.',
      content: {
        'application/json': {
          schema: CreateGigJobResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * PUT /gig-jobs/{gigJobId}
 */
registry.registerPath({
  operationId: 'updateGigJob',
  path: `${basePath}/{gigJobId}`,
  description: 'Update a gig job.',
  method: 'put',
  request: {
    params: z.object({
      gigJobId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: UpdateGigJobBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Gig job updated successfully.',
      content: {
        'application/json': {
          schema: UpdateGigJobResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /gig-jobs/rider/{riderId}
 */
registry.registerPath({
  operationId: 'getGigJobsByRiderId',
  path: `${basePath}/rider/{riderId}`,
  description: 'Get gig jobs by rider ID.',
  method: 'get',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
    query: GetGigJobsByRiderIdQuerySchema,
  },
  responses: {
    200: {
      description: 'Gig jobs fetched successfully.',
      content: {
        'application/json': {
          schema: GetGigJobsByRiderIdResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /gig-jobs/cancel/{gigJobId}
 */
registry.registerPath({
  operationId: 'cancelGigJob',
  path: `${basePath}/cancel/{gigJobId}`,
  description: 'Cancel a gig job.',
  method: 'post',
  request: {
    params: z.object({
      gigJobId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Gig job cancelled successfully.',
      content: {
        'application/json': {
          schema: CancelGigJobResponseSchema,
        },
      },
    },
    409: {
      description: 'Gig job is already cancelled.',
      content: {
        'application/json': {
          schema: ApiErrorSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /gig-jobs/accept/{gigJobId}/{riderId}
 */
registry.registerPath({
  operationId: 'acceptGigJob',
  path: `${basePath}/accept/{gigJobId}/{riderId}`,
  description: 'Accept a gig job.',
  method: 'post',
  request: {
    params: z.object({
      gigJobId: z.string(),
      riderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Gig job accepted successfully.',
      content: {
        'application/json': {
          schema: AcceptGigJobResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /gig-jobs/reject/{gigJobId}/{riderId}
 */
registry.registerPath({
  operationId: 'rejectGigJob',
  path: `${basePath}/reject/{gigJobId}/{riderId}`,
  description: 'Reject a gig job.',
  method: 'post',
  request: {
    params: z.object({
      gigJobId: z.string(),
      riderId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: RejectGigJobBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Gig job rejected successfully.',
      content: {
        'application/json': {
          schema: RejectGigJobResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /gig-jobs/reject/response/{gigJobId}/{riderId}
 */
registry.registerPath({
  operationId: 'rejectGigJobResponse',
  path: `${basePath}/reject/response/{gigJobId}/{riderId}`,
  description: 'Reject a gig job response.',
  method: 'post',
  request: {
    params: z.object({
      gigJobId: z.string(),
      riderId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: RejectGigJobResponseBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Gig job response rejected successfully.',
      content: {
        'application/json': {
          schema: RejectGigJobResponseResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /gig-jobs/accept/response/{gigJobId}/{riderId}
 */
registry.registerPath({
  operationId: 'acceptGigJobResponse',
  path: `${basePath}/accept/response/{gigJobId}/{riderId}`,
  description: 'Accept a gig job response.',
  method: 'post',
  request: {
    params: z.object({
      gigJobId: z.string(),
      riderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Gig job response accepted successfully.',
      content: {
        'application/json': {
          schema: AcceptGigJobResponseResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /gig-jobs/mark-as-paid
 */
registry.registerPath({
  operationId: 'markGigJobsAsPaid',
  path: `${basePath}/mark-as-paid`,
  description: 'Mark gig jobs as paid.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: MarkGigJobsAsPaidBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Gig jobs marked as paid successfully.',
      content: {
        'application/json': {
          schema: MarkGigJobsAsPaidResponseSchema
        }
      }
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /gig-jobs/payment-summary
 */
registry.registerPath({
  operationId: 'getGigJobPaymentSummary',
  path: `${basePath}/payment-summary`,
  description: 'Get gig job payment summary.',
  method: 'get',
  request: {
    query: GetGigJobPaymentSummaryQuerySchema,
  },
  responses: {
    200: {
      description: 'Gig job payment summary fetched successfully.',
      content: {
        'application/json': {
          schema: GetGigJobPaymentSummaryResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /gig-jobs/download-payment-summary
 */
registry.registerPath({
  operationId: 'downloadGigJobPaymentSummary',
  path: `${basePath}/download-payment-summary`,
  description: 'Download gig job payment summary.',
  method: 'get',
  request: {
    body: {
      content: {
        'application/json': {
          schema: DownloadGigJobPaymentSummaryQuerySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Gig job payment summary downloaded successfully.',
      content: {
        'application/json': {
          schema: DownloadGigJobPaymentSummaryResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /gig-jobs/download-gig-jobs-csv
 */
registry.registerPath({
  operationId: 'downloadGigJobs',
  path: `${basePath}/download-gig-jobs-csv`,
  description: 'Download gig jobs.',
  method: 'get',
  request: {
    body: {
      content: {
        'application/json': {
          schema: DownloadGigJobsQuerySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Gig jobs downloaded successfully.',
      content: {
        'application/json': {
          schema: DownloadGigJobsResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /gig-jobs/priority-check/{riderId}
 */
registry.registerPath({
  operationId: 'checkRiderPriorityDeliveries',
  path: `${basePath}/priority-check/{riderId}`,
  description: 'Check if a rider has active priority deliveries.',
  method: 'get',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Rider priority deliveries checked successfully.',
      content: {
        'application/json': {
          schema: CheckRiderPriorityResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

export const gigJobsApiRegistry = registry;
