import { ExternalCourier, ExternalCourierLocation } from '@tradeaze-packages/schemas';
import { MapMarkerProps } from '../Map';
import { RiderMarker } from '../RiderMarker';

export const createExternalCourierMarker = (
  courier: ExternalCourier,
  location: ExternalCourierLocation,
  options: {
    isAdmin?: boolean;
    alwaysShowDetails?: boolean;
  }
): MapMarkerProps | undefined => {
  const { isAdmin, alwaysShowDetails = true } = options;

  if (!courier || !location) {
    return undefined;
  }

  return {
    id: courier.id,
    type: 'RIDER',
    children: (
      <RiderMarker
        externalCourier={courier}
        updatedAt={courier.updatedAt}
        alwaysShowDetails={alwaysShowDetails}
        isAdmin={isAdmin ?? false}
      />
    ),
    position: location,
    anchor: 'center',
    updateZoom: false,
  };
}; 