import { MerchantAccount } from '@tradeaze-packages/schemas';
import { NavigateFunction } from 'react-router-dom';
import { MapMarkerProps } from '../Map';
import { MerchantMarker } from '../MerchantMarker';

export const createMerchantMarker = (
  merchant: MerchantAccount,
  options: {
    selectedMerchantId?: string;
    isAdmin?: boolean;
    navigate?: NavigateFunction;
  }
): MapMarkerProps | undefined => {
  const { selectedMerchantId, isAdmin, navigate } = options;

  if (!merchant.position || (selectedMerchantId && selectedMerchantId !== merchant.merchantId)) {
    return undefined;
  }

  return {
    id: merchant.merchantId,
    type: 'MERCHANT',
    children: (
      <MerchantMarker
        name={merchant.merchantName}
        onClick={isAdmin && navigate ? () => navigate(`/merchants/${merchant.merchantId}`) : undefined}
      />
    ),
    position: merchant.position,
    anchor: 'center',
    data: { merchantId: merchant.merchantId },
    updateZoom: false,
  };
}; 