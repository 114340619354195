import { useMemo } from 'react';
import {
  useSubscribeToExternalCourierLocations,
  useSubscribeToRiderLocations,
} from '@tradeaze/frontend/hooks';
import { HydratedOrder } from '@tradeaze-packages/schemas';
import { useDeliveryStopMarkers } from './useDeliveryStopMarkers';
import { useRiderMarkers } from './useRiderMarkers';
import { useExternalCourierMarkers } from './useExternalCourierMarkers';
import { useCompositeMarkers } from '../../map';

type OrderPageMarkersProps = {
  order?: HydratedOrder;
  isAdmin?: boolean;
};

export const useOrderPageMarkers = ({
  order,
  isAdmin = false,
}: OrderPageMarkersProps = {}) => {
  const riders = useMemo(
    () =>
      order?.deliveries
        .map((d) => d.rider)
        .filter((r): r is NonNullable<typeof r> => Boolean(r)) ?? [],
    [order],
  );

  const externalCouriers = useMemo(
    () =>
      order?.deliveries
        .map((d) => d.externalCourier)
        .filter((ec): ec is NonNullable<typeof ec> => Boolean(ec)) ?? [],
    [order],
  );

  const riderLocations = useSubscribeToRiderLocations(
    riders.map((r) => r?.riderId || ''),
  );

  const externalCourierLocations = useSubscribeToExternalCourierLocations(
    externalCouriers.map((ec) => ec?.id || ''),
  );

  const deliveryStopMarkers = useDeliveryStopMarkers(order);

  const riderMarkers = useRiderMarkers(order, riderLocations, riders, isAdmin);

  const courierMarkers = useExternalCourierMarkers(
    order,
    externalCourierLocations,
    externalCouriers,
    isAdmin,
  );

  return useCompositeMarkers({
    markerSets: [deliveryStopMarkers, riderMarkers, courierMarkers],
  });
};
