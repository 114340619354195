import {
  ExternalCourier,
  ExternalCourierLocationMap,
  HydratedOrder,
} from '@tradeaze-packages/schemas';
import { useMemo } from 'react';
import { MapMarkerProps, createExternalCourierMarker } from '../../map';

export const useExternalCourierMarkers = (
  order?: HydratedOrder,
  externalCourierLocations?: ExternalCourierLocationMap,
  externalCouriers?: ExternalCourier[],
  isAdmin?: boolean
) => {
  return useMemo<MapMarkerProps[]>(() => {
    if (!order || !externalCourierLocations || !externalCouriers) {
      return [];
    }

    return externalCouriers
      .map((courier) => {
        const location = externalCourierLocations[courier.id];
        if (!location) return undefined;

        return createExternalCourierMarker(courier, location, {
          isAdmin,
          alwaysShowDetails: true
        });
      })
      .filter((marker): marker is MapMarkerProps => Boolean(marker));
  }, [order, externalCourierLocations, externalCouriers, isAdmin]);
};
