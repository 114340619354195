import { useEffect, useMemo, useState } from 'react';
import { ExternalCourier } from '@tradeaze-packages/schemas';
import { SupabaseClient } from '@supabase/supabase-js';
import { useGetExternalCourierLocations } from './useGetExternalCourierLocation';

const supabase = new SupabaseClient(
  import.meta.env['VITE_SUPABASE_URL'],
  import.meta.env['VITE_SUPABASE_KEY']
);

export const useSubscribeToExternalCourierLocations = (
  externalCourierIds: string[],
  options: {
    enabled: boolean;
  } = { enabled: true }
) => {
  const externalCourierLocationsQuery = useGetExternalCourierLocations(externalCourierIds, {
    enabled: externalCourierIds.length > 0 && options.enabled,
  });

  const [externalCourierLocationMap, setExternalCourierLocationMap] = useState(
    externalCourierLocationsQuery.data
  );

  useEffect(() => {
    setExternalCourierLocationMap(externalCourierLocationsQuery.data);
  }, [externalCourierLocationsQuery.data]);

  useEffect(() => {
    if (!options.enabled) return;
    const channel = supabase
      .channel('external-rider-location-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'ExternalCourier',
        },
        (payload) => {
          const updatedLocation = payload.new as ExternalCourier & {
            riderId: string;
          };
          const position = updatedLocation.position;
          if (!position) return;
          const locationObj = {
            latitude: position.latitude,
            longitude: position.longitude,
            updatedAt: updatedLocation.updatedAt,
          }
          setExternalCourierLocationMap((prev) => ({
            ...prev,
            [updatedLocation.id]: locationObj,
          }));
        }
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [options.enabled]);

  return useMemo(() => externalCourierLocationMap, [externalCourierLocationMap]);
};

export const useSubscribeToExternalRiderLocation = (
  riderId: string,
  options: {
    enabled: boolean;
  } = { enabled: true }
) => {
  return useSubscribeToExternalCourierLocations([riderId], options)?.[riderId];
};
