import { useMemo } from 'react';
import { RiderAccount, RiderIdentity, RiderLocationMap } from '@tradeaze-packages/schemas';
import { MapMarkerProps, createRiderMarker } from '../../../map';

type RiderMarkersProps = {
  riders?: (RiderIdentity & Pick<RiderAccount, 'lastClockedInAt' | 'onDuty'>)[];
  riderLocations?: RiderLocationMap;
  isAdmin?: boolean;
};

export const useDashboardRiderMarkers = ({
  riders,
  riderLocations,
  isAdmin,
}: RiderMarkersProps) => {
  return useMemo<MapMarkerProps[]>(() => {
    if (!riders || !riderLocations) {
      return [];
    }

    return riders
      .map((rider) => {
        const location = riderLocations[rider.riderId];
        if (!location) return undefined;

        return createRiderMarker(rider, location, {
          isAdmin,
          alwaysShowDetails: false,
          hideClockedOut: true
        });
      })
      .filter((marker): marker is MapMarkerProps => Boolean(marker));
  }, [riders, riderLocations, isAdmin]);
}; 