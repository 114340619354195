import { useMemo } from 'react';
import { MapMarkerProps } from '../Map';

export type MarkerFilter = (marker: MapMarkerProps) => boolean;

type CompositeMarkersProps = {
  markerSets: MapMarkerProps[][];
  filter?: MarkerFilter;
};

export const useCompositeMarkers = ({
  markerSets,
  filter,
}: CompositeMarkersProps): MapMarkerProps[] => {
  return useMemo(() => {
    const combinedMarkers = markerSets.flat();
    return filter ? combinedMarkers.filter(filter) : combinedMarkers;
  }, [markerSets, filter]);
}; 