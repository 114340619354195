import React, { useState } from 'react';
import { Box, HStack, Icon, Stack, Text, Tooltip } from '@chakra-ui/react';
import { ExternalCourier, RiderIdentity } from '@tradeaze-packages/schemas';
import { formatDate } from '@tradeaze/shared/utils';
import { differenceInMinutes } from 'date-fns';
import { FaHourglassHalf } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { RiderAvatar } from '../rider/RiderAvatar';
import { DeliveryVehicleIcon } from '../delivery';

const idleTimeToColor = (idleTime: number) => {
  if (idleTime < 5) {
    return 'blackAlpha.500';
  } else if (idleTime < 15) {
    return 'orange.300';
  } else {
    return 'red.400';
  }
};

type Props = (
  | {
      rider: RiderIdentity;
      externalCourier?: never;
    }
  | {
      rider?: never;
      externalCourier: ExternalCourier;
    }
) & {
  updatedAt?: string;
  alwaysShowDetails?: boolean;
  showProfilePic?: boolean;
  isAdmin: boolean;
};

export const RiderMarker: React.FC<Props> = ({
  rider,
  externalCourier,
  updatedAt,
  alwaysShowDetails = false,
  showProfilePic = false,
  isAdmin,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const idleTime = updatedAt
    ? differenceInMinutes(new Date(), new Date(updatedAt))
    : 0;

  const showDriverType = isAdmin;
  const showInactive = isAdmin;
  const showContactNumber =
    isAdmin && (rider?.contactNumber || externalCourier?.contactNumber);

  const isInactive = idleTime > 15;

  const handleNumberClick = (number: string) => {
    navigator.clipboard.writeText(number);
    toast.success('Copied to clipboard');
  };

  const firstName = rider?.firstName || externalCourier?.firstName;
  const lastName = rider?.lastName || externalCourier?.lastName;
  const contactNumber = rider?.contactNumber || externalCourier?.contactNumber;
  const workType = rider?.workType || 'EXTERNAL';

  return (
    <Stack
      alignItems="center"
      position={'relative'}
      onMouseOver={() => setShowDetails(true)}
      onMouseLeave={() => setShowDetails(false)}
    >
      <RiderAvatar
        firstName={firstName}
        lastName={lastName}
        size={'xs'}
        profilePicKey={rider?.avatarUrl}
        showProfilePic={showProfilePic}
      />
      {showInactive && isInactive ? (
        <Icon
          as={FaHourglassHalf}
          position={'absolute'}
          top={-2}
          right={-2}
          fontSize={12}
          color={idleTimeToColor(idleTime)}
        />
      ) : null}
      {(showDetails || alwaysShowDetails) && (
        <Box
          fontSize={11}
          bg="white"
          px={2}
          borderRadius={10}
          shadow={'xl'}
          position={'absolute'}
          top={showContactNumber ? -14 : -12}
          whiteSpace={'nowrap'}
        >
          <HStack>
            <Text>
              <Text as="span">{firstName}</Text>
              {showDriverType ? (
                <Text as="span">{` (${workType})`}</Text>
              ) : null}
            </Text>
            {rider?.deliveryVehicle ? (
              <DeliveryVehicleIcon
                fontSize={14}
                deliveryVehicle={rider.deliveryVehicle}
              />
            ) : null}
          </HStack>
          {showContactNumber ? (
            <Tooltip label="Click to copy" placement="top">
              <Text
                fontSize={10}
                cursor="pointer"
                _hover={{ color: 'blue.500' }}
                onClick={() => handleNumberClick(contactNumber ?? '')}
                textDecoration="underline"
                textDecorationStyle="dotted"
              >
                {contactNumber}
              </Text>
            </Tooltip>
          ) : null}
          {updatedAt ? <Text>{formatDate(new Date(updatedAt))}</Text> : null}
        </Box>
      )}
    </Stack>
  );
};
