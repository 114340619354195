import { RiderIdentity, RiderLocationMap, HydratedOrder } from '@tradeaze-packages/schemas';
import { useMemo } from 'react';
import { MapMarkerProps } from '../../map';
import { createRiderMarker } from '../../map/';

export const useRiderMarkers = (
  order?: HydratedOrder,
  riderLocations?: RiderLocationMap,
  riders?: RiderIdentity[],
  isAdmin?: boolean
) => {
  return useMemo<MapMarkerProps[]>(() => {
    if (!order || !riderLocations || !riders) {
      return [];
    }

    return riders
      .map((rider) => {
        const location = riderLocations[rider.riderId];
        if (!location) return undefined;

        return createRiderMarker(rider, location, {
          isAdmin,
          alwaysShowDetails: true,
          hideClockedOut: false
        });
      })
      .filter((marker): marker is MapMarkerProps => Boolean(marker));
  }, [order, riderLocations, riders, isAdmin]);
};
