import {
  RiderIdentity,
  RiderLocation,
} from '@tradeaze-packages/schemas';
import { MapMarkerProps } from '../Map';
import { RiderMarker } from '../RiderMarker';
import { shouldShowRiderMarker } from '../utils/shouldShowRiderMarker';
import { getIsOnDuty } from '@tradeaze/shared/utils';

export const createRiderMarker = (
  rider: RiderIdentity & {
    lastClockedInAt?: string | null;
    onDuty?: boolean | null;
  },
  location: RiderLocation,
  options: {
    isAdmin?: boolean;
    alwaysShowDetails?: boolean;
    hideClockedOut?: boolean;
  },
): MapMarkerProps | undefined => {
  const { isAdmin, alwaysShowDetails = false, hideClockedOut = true } = options;

  if (
    !shouldShowRiderMarker({
      updatedAt: location.updatedAt,
      isOnDuty: getIsOnDuty(rider.lastClockedInAt ?? null, rider.onDuty),
      hideClockedOut,
    })
  ) {
    return undefined;
  }

  return {
    id: rider.riderId,
    type: 'RIDER',
    children: (
      <RiderMarker
        rider={rider}
        updatedAt={location.updatedAt}
        alwaysShowDetails={alwaysShowDetails}
        isAdmin={isAdmin ?? false}
      />
    ),
    position: location,
    anchor: 'center',
    updateZoom: false,
  };
};
