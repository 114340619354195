import { z } from 'zod';
import { DeliveryOptionIdSchema } from './delivery-option-schema';
import { DeliveryVehicleIdSchema } from './delivery-vehicle-schema';
import {
  DropoffDeliveryStopSchema,
  CreateDeliveryStopContactSchema,
  PickupDeliveryStopSchema,
} from './delivery-stop-schema';

import {
  CreateDeliveryItemSchema,
  DeliveryItemSchema,
} from './delivery-item-schema';
import { PaginationParamsSchema } from './paginated-data-schema';
import { buildArrayOrSingleSchema } from '../utils';
import { DeliveryStatusSchema } from './delivery-status-schema';
import { WorkTypeSchema } from './work-type-schema';

const DeliverySortBySchema = z.enum([
  'createdAt',
  'deliveredAt',
  'deliveryWindowEnd',
]);

export const createDeliveryStopOmittedFields = {
  createdAt: true,
  updatedAt: true,
  arrivedAt: true,
  completedAt: true,
} as const;

export const createDeliveryOmittedFields = {
  createdAt: true,
  updatedAt: true,
  confirmedAt: true,
  deliveredAt: true,
  cancelledAt: true,
  completedAt: true,
} as const;

export const DeliverySchema = z.object({
  deliveryId: z.string(),
  orderId: z.string(),
  pickupId: z.string(),
  dropOffId: z.string(),
  status: DeliveryStatusSchema,
  createdAt: z.string(),
  updatedAt: z.string(),
  riderId: z.string().nullish(),
  tips: z.number().nullish(),
  deliveryOptionId: DeliveryOptionIdSchema,
  deliveryVehicleId: DeliveryVehicleIdSchema,
  cancellationReason: z.string().nullish(),
  confirmedAt: z.string().nullish(),
  deliveredAt: z.string().nullish(),
  cancelledAt: z.string().nullish(),
  heavySideItems: z.boolean().nullish(),
  collectionReady: z.boolean().nullish(),
  externalOrderId: z.string().nullish(),
  externalJobId: z.string().nullish(),
});

export const CreateDeliverySchema = DeliverySchema.omit(
  createDeliveryOmittedFields,
).extend({
  deliveryId: z.string().optional(),
  pickup: PickupDeliveryStopSchema.omit(createDeliveryStopOmittedFields).extend(
    {
      orderId: z.string().optional(),
      collectionReference: z
        .string()
        .min(1, { message: 'Collection reference is required' }),
    },
  ),
  dropOff: DropoffDeliveryStopSchema.omit(
    createDeliveryStopOmittedFields,
  ).extend({
    orderId: z.string().optional(),
    contacts: z
      .array(CreateDeliveryStopContactSchema)
      .min(1, { message: 'At least one contact is required' })
      .max(5, { message: 'Maximum of five contacts allowed' }),
  }),
  deliveryItems: z.array(CreateDeliveryItemSchema),
  riderId: z.string().optional(),
});

export const UpdateDeliverySchema = DeliverySchema.extend({
  pickup: PickupDeliveryStopSchema.omit(createDeliveryStopOmittedFields).extend(
    {
      collectionReference: z
        .string()
        .min(1, { message: 'Collection reference is required' }),
    },
  ),
  dropOff: DropoffDeliveryStopSchema.omit(
    createDeliveryStopOmittedFields,
  ).extend({
    contacts: z
      .array(CreateDeliveryStopContactSchema)
      .min(1, { message: 'At least one contact is required' })
      .max(5, { message: 'Maximum of five contacts allowed' }),
  }),
  deliveryItems: z.array(DeliveryItemSchema),
  riderId: z.string().optional(),
});

/**
 * Query Params for querying deliveries
 */
export const DeliveriesArrayQueryParamsSchema = z.object({
  deliveryStatuses: buildArrayOrSingleSchema(DeliveryStatusSchema, false),
  riderIds: buildArrayOrSingleSchema(z.string(), false),
  deliveryVehicles: buildArrayOrSingleSchema(DeliveryVehicleIdSchema, false),
  merchantIds: buildArrayOrSingleSchema(z.string(), false),
  workTypes: buildArrayOrSingleSchema(WorkTypeSchema, false),
});

export const DeliveriesStringQueryParamsSchema = z.object({
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  sortBy: DeliverySortBySchema.optional(),
  deliveryToday: z.preprocess(
    (arg) => (arg === 'true' ? true : arg === 'false' ? false : undefined),
    z.boolean().optional(),
  ),
  merchantId: z.string().optional(),
  merchantOrderReference: z.string().optional(),
  ...PaginationParamsSchema.shape,
});

export const DeliveriesQueryParamsSchema = z.object({
  ...DeliveriesArrayQueryParamsSchema.shape,
  ...DeliveriesStringQueryParamsSchema.shape,
});

export type Delivery = z.infer<typeof DeliverySchema>;
export type DeliverySortBy = z.infer<typeof DeliverySortBySchema>;
export type CreateDelivery = z.infer<typeof CreateDeliverySchema>;
export type UpdateDelivery = z.infer<typeof UpdateDeliverySchema>;
export type DeliveriesQueryParams = z.infer<typeof DeliveriesQueryParamsSchema>;
