import { DeliveryStop, HydratedDelivery, HydratedOrder } from '@tradeaze-packages/schemas';
import { MapMarkerProps } from '../Map';
import { DeliveryMarker } from '../DeliveryMarker';

export const createDeliveryStopMarker = (
  stop: DeliveryStop,
  delivery: HydratedDelivery,
  options: {
    isAdmin?: boolean;
    isHovered?: boolean;
    isSelected?: boolean;
    onHover?: () => void;
    onHoverLeave?: () => void;
    onClickDelivery?: (order: HydratedOrder) => void;
  }
): MapMarkerProps | undefined => {
  const { isAdmin, isHovered, isSelected, onHover, onHoverLeave, onClickDelivery } = options;

  if (!stop.position) {
    return undefined;
  }

  const deliveryPostcode = delivery.dropOff.postCode;
  const pickUpPostcode = delivery.pickup.postCode;

  return {
    id: stop.deliveryStopId,
    type: 'DELIVERY',
    position: stop.position,
    updateZoom: false,
    children: (
      <DeliveryMarker
        opacity={stop.completedAt ? 0.5 : 1}
        deliveryId={delivery.deliveryId}
        isAdmin={isAdmin}
        deliveryWindowEnd={stop.windowEnd}
        deliveryPostcode={deliveryPostcode}
        pickUpPostcode={pickUpPostcode}
        rider={delivery.rider ? {
          firstName: delivery.rider.firstName,
          lastName: delivery.rider.lastName,
        } : undefined}
        deliveryVehicle={delivery.deliveryVehicleId}
        stopType={stop.type}
        onClick={() => onClickDelivery?.(delivery.order as HydratedOrder)}
        isSelected={isSelected}
        isHovered={isHovered}
        onHover={onHover}
        onHoverLeave={onHoverLeave}
      />
    ),
  };
}; 