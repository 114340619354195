import { z } from 'zod';
import { PositionSchema } from './shared-schema';

const ExternalCourierLocationSchema = PositionSchema;

export const ExternalCourierSchema = z.object({
  id: z.string(),
  externalJobId: z.string(),
  deliveryId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  contactNumber: z.string(),
  position: ExternalCourierLocationSchema.nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const ExternalCourierLocationMapSchema = z.record(z.string(), ExternalCourierLocationSchema);

export type ExternalCourier = z.infer<typeof ExternalCourierSchema>;
export type ExternalCourierLocation = z.infer<typeof ExternalCourierLocationSchema>;
export type ExternalCourierLocationMap = z.infer<typeof ExternalCourierLocationMapSchema>;
