import { HydratedOrder } from '@tradeaze-packages/schemas';
import { useMemo } from 'react';
import { MapMarkerProps, createDeliveryStopMarker } from '../../map';

export const useDeliveryStopMarkers = (order?: HydratedOrder) => {
  return useMemo<MapMarkerProps[]>(() => {
    if (!order) {
      return [];
    }

    return order.deliveries.flatMap((delivery) => {
      const deliveryWithOrder = { ...delivery, order };
      return [delivery.pickup, delivery.dropOff]
        .map((stop) =>
          createDeliveryStopMarker(stop, deliveryWithOrder, {
            isAdmin: false,
            onClickDelivery: undefined,
          }),
        )
        .filter((marker): marker is MapMarkerProps => Boolean(marker));
    });
  }, [order]);
};
