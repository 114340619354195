import { z } from 'zod';
import { GetGigJobsQuerySchema } from './get-gig-jobs-schema';

export const DownloadGigJobsQuerySchema = GetGigJobsQuerySchema

export const DownloadGigJobsResponseSchema = z.object({
  url: z.string(),
});

export type DownloadGigJobsQuery = z.infer<
  typeof DownloadGigJobsQuerySchema
>;
export type DownloadGigJobsResponse = z.infer<
  typeof DownloadGigJobsResponseSchema
>;
