import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { fetchExternalRiderLocation } from '@tradeaze-packages/api-client';

type Response = Awaited<ReturnType<typeof fetchExternalRiderLocation>>;

export const getExternalCourierLocationsQueryKey = (courierIds?: string[]) => {
  const key: (string | object)[] = ['getExternalRiderLocations']
  if (courierIds) {
    key.push(...courierIds);
  }
  return key;
}

export const useGetExternalCourierLocations = (
  courierIds: string[],
  options?: UseQueryOptions<Response>
) =>
  useQuery<Response>({
    queryKey: getExternalCourierLocationsQueryKey(courierIds),
    queryFn: async () => fetchExternalRiderLocation(courierIds),
    ...options,
  });
