import {
  ExternalCourier,
  ExternalCourierLocationMap,
} from '@tradeaze-packages/schemas';
import { useMemo } from 'react';
import { MapMarkerProps, createExternalCourierMarker } from '../../../map';

type ExternalCourierMarkersProps = {
  externalCouriers?: ExternalCourier[];
  externalCourierLocations?: ExternalCourierLocationMap;
  isAdmin?: boolean;
};

export const useDashboardExternalCourierMarkers = ({
  externalCouriers,
  externalCourierLocations,
  isAdmin,
}: ExternalCourierMarkersProps) => {
  return useMemo<MapMarkerProps[]>(() => {
    if (!externalCouriers || !externalCourierLocations) {
      return [];
    }

    return externalCouriers
      .map((courier) => {
        const location = externalCourierLocations[courier.id];
        if (!location) return undefined;

        return createExternalCourierMarker(courier, location, {
          isAdmin,
          alwaysShowDetails: true,
        });
      })
      .filter((marker): marker is MapMarkerProps => Boolean(marker));
  }, [externalCouriers, externalCourierLocations, isAdmin]);
};
