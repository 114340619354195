import { useMemo } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { MerchantAccount, HydratedDelivery } from '@tradeaze-packages/schemas';
import { MapMarkerProps } from '../../../map';
import { createMerchantMarker } from '../../../map/';

type MerchantMarkersProps = {
  merchants?: MerchantAccount[];
  selectedDelivery?: HydratedDelivery;
  isAdmin?: boolean;
  navigate: NavigateFunction;
};

export const useDashboardMerchantMarkers = ({
  merchants,
  selectedDelivery,
  isAdmin,
  navigate,
}: MerchantMarkersProps) => {
  return useMemo<MapMarkerProps[]>(() => {
    if (!merchants) {
      return [];
    }

    return merchants
      .map((merchant) =>
        createMerchantMarker(merchant, {
          selectedMerchantId: selectedDelivery?.merchant?.merchantId,
          isAdmin,
          navigate,
        })
      )
      .filter((marker): marker is MapMarkerProps => Boolean(marker));
  }, [merchants, navigate, selectedDelivery, isAdmin]);
};